/*
 * npm install react-scripts --save-dev
 * npm run deploy
 */

import React from "react";
import HeroSection from "../HeroSection";
import '../../App.css';

function Home(){
    return (
        <div>
            <HeroSection />
        </div>
    )
}

export default Home;